body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.landing-page {
  height: 100vh;
  overflow: hidden;
  background-color: #000;
}

.top-image-container {
  position: fixed;
  top: auto;
  z-index: 1;
  width: 100%;
}

.top-image-container img {
  margin-top: 50px;
  width: 100%;
  height: auto;
  display: block;
}

.bottom-image-container {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.bottom-image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.steam-button {
  position: fixed;
  padding: 0;
  bottom: 80px;
  right: 100px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #000;
  z-index: 1;
}


.steam-icon {
  width: 100%;
  height: 100px;
}
@media (max-width: 768px) {
  .bottom-image-container img {
    transform: scale(1.5);
  }
  .steam-button {
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }
}
